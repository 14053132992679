var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('v-container',{attrs:{"fluid":""}},[(_vm.processos.length)?_c('v-card',{attrs:{"rounded":"","width":"100%"}},[_c('v-card-title',{staticClass:"justify-center mb-3 titulo"},[_c('h2',[_vm._v(" Lançamento de Resultados ")])]),_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('EscolhaProcessoAutocomplete',{attrs:{"disabled":_vm.tab === 1,"processos":_vm.processos},model:{value:(_vm.processoSelecionado),callback:function ($$v) {_vm.processoSelecionado=$$v},expression:"processoSelecionado"}})],1)],1),_c('v-divider',{staticClass:"my-5"}),_c('v-window',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-window-item',[(
                _vm.opcoes.length > 0 &&
                  Object.keys(_vm.processoSelecionado).length > 0
              )?_c('v-card',[_c('v-card-title',[_vm._v(" Opções "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":"","name":"buscarOpcao","dense":""},model:{value:(_vm.buscar),callback:function ($$v) {_vm.buscar=$$v},expression:"buscar"}})],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"disable-pagination":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.opcoes,"search":_vm.buscar},on:{"click:row":_vm.carregarOpcao},scopedSlots:_vm._u([{key:"item.situacoesLancadas",fn:function(ref){
              var item = ref.item;
return [_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[(item.situacoesLancadas)?_c('v-icon',{staticClass:"mr-1",attrs:{"color":"primary","size":"21"}},[_vm._v("mdi-check-circle-outline ")]):_c('v-icon',{staticClass:"mr-1",attrs:{"color":"amber","size":"21"}},[_vm._v("mdi-alert-circle-outline ")]),_vm._v(" "+_vm._s(item.situacoesLancadas ? "Ok" : "Pendente")+" ")],1)],1)]}},{key:"item.documentosAvaliados",fn:function(ref){
              var item = ref.item;
return [_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[(item.documentosAvaliados)?_c('v-icon',{staticClass:"mr-1",attrs:{"color":"primary","size":"21"}},[_vm._v("mdi-check-circle-outline ")]):_c('v-icon',{staticClass:"mr-1",attrs:{"color":"amber","size":"21"}},[_vm._v(" mdi-alert-circle-outline ")]),_vm._v(" "+_vm._s(item.documentosAvaliados ? "Ok" : "Pendente")+" ")],1)],1)]}},{key:"item.parecerLancado",fn:function(ref){
              var item = ref.item;
return [_c('v-container',[(!_vm.pareceresLancados && !_vm.processoSelecionado.lancamentoParecer)?_c('v-row',{attrs:{"justify":"center"}},[_c('span',[_c('b',[_vm._v("-")])])]):_c('v-row',{attrs:{"justify":"center"}},[(item.parecerLancado)?_c('v-icon',{staticClass:"mr-1",attrs:{"color":"primary","size":"21"}},[_vm._v("mdi-check-circle-outline ")]):_c('v-icon',{staticClass:"mr-1",attrs:{"color":"amber","size":"21"}},[_vm._v(" mdi-alert-circle-outline ")]),_vm._v(" "+_vm._s(item.parecerLancado ? "Ok" : "Pendente")+" ")],1)],1)]}},{key:"item.notasLancadas",fn:function(ref){
              var item = ref.item;
return [_c('v-container',[(!_vm.processoSelecionado.possuiTipoNota)?_c('v-row',{attrs:{"justify":"center"}},[_c('span',[_c('b',[_vm._v("-")])])]):_c('v-row',{attrs:{"justify":"center"}},[(item.notasLancadas)?_c('v-icon',{staticClass:"mr-1",attrs:{"color":"primary","size":"21"}},[_vm._v("mdi-check-circle-outline ")]):_c('v-icon',{staticClass:"mr-1",attrs:{"color":"amber","size":"21"}},[_vm._v(" mdi-alert-circle-outline ")]),_vm._v(" "+_vm._s(item.notasLancadas ? "Ok" : "Pendente")+" ")],1)],1)]}}],null,false,3625248616)})],1)],1):_vm._e()],1),_c('v-window-item',[(_vm.tab === 1)?_c('Lancamento',{attrs:{"opcao":_vm.opcaoSelecionada,"processo":_vm.processoSelecionado},on:{"voltar":_vm.voltarTab,"lancamentoRealizado":_vm.atualizarOpcoes}}):_vm._e()],1)],1)],1)],1)],1):_c('v-row',{staticClass:"mt-10",attrs:{"align":"center","justify":"center"}},[_c('v-alert',{attrs:{"type":"info","prominent":"","colored-border":"","border":"left","elevation":"1"}},[_vm._v(" Não existem processos para lançamento de resultados ")])],1),(_vm.processoSelecionado.oid && !_vm.opcoes.length && !_vm.loadingOpcoes)?_c('v-row',{staticClass:"mt-10",attrs:{"align":"center","justify":"center"}},[_c('v-alert',{attrs:{"type":"info","prominent":"","colored-border":"","border":"left","elevation":"1"}},[_vm._v(" Não existem opções para lançamento de resultados ")])],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }